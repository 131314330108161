<template>
    <div class="p-1">
       <div-table :config="configList" :data="sourcings" :idWharhouse="idWharhouse" :currentUser="currentUser" :sellers="sellers"  :element="element" :statusShipp="statusExpedition" :ProcessingInfo="ProcessingInfo"  @reset="reset"  @refresh="refresh" @showProducts="showProducts" @filtrer="filtrer" @search="search" :loading="loading" :paginate="paginate" @paginatation="paginatation"></div-table>
    </div>
</template>
<script>
import button from "../../components/~/button.vue";
import config from './config'
export default {
    props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object}
   },
    data() {
        return {
            configList: config.list,
            loading:false,
            sourcings: [],
            element: {},
            show_modal: false,
            limit: 100,
            filters:{},
            paginate: {
                total: 0,
                currentpage: 1,
                lastpage: 0,
                per_page: 0,
            },
            sellers: [],
            statusExpedition: ['pending','en attente de paiement','en attente de validation','valide','rejected'],
            country:this.$countries.filter(item => item.name == 'Turkey' || item.name == 'Morocco'  || item.name == 'United Arab Emirates'  || item.name == 'China'  ),
            ProcessingInfo:{count:0,total:0},
            warehouses:[]
        };
    },
    computed: {
        warhouseSelected(){
            return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
        },
    }, 
    watch: { 
        'warhouseSelected': async function(oldVal, newVal) {
            
        },
        'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
            await this.reset();
            if (this.currentUser.type != "Seller") {  await this.getSellers();this.setDataSelect("seller", this.sellers, null);} 
        }
    },
    async mounted() {
         this.currentUser.type2=this.currentUser.type==='Seller'?this.currentUser.type:'COD';
         if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
         else this.$f.objectToQueryString({limit:this.limit})

        if(this.filters.name) await this.search(this.filters.name);
        else await this.getSourcings(this.filters);
        if (this.currentUser.type !== "Seller") {
            await this.getSellers();
            this.setDataSelect("seller", this.sellers, null);
        }
        this.setDataSelect("status", this.statusExpedition, null);
        this.setDataSelect("country", this.country, null);
        if (this.currentUser.type == "Seller") {
            let pos = this.configList.columns.map(function (e) {return e.name;}).indexOf('Seller');
            if(pos!=-1)  this.configList.columns.splice(pos, 1);           
        }
        await this.getCurrentWarehouses();
        this.setDataSelect("warehouse", this.warehouses, null);

    },
    methods: {
        async getwharhouseId(){
           await this.$store.dispatch('wharhouse/getwharhouseId',{country: this.warhouseSelected,}).then((res) => {this.idWharhouse=res;})
       }, 
       async getCurrentWarehouses() {
          var newArray=[]
          const res = await this.$server.search("warehouses",{limit:100});
          if (res && res.content.results) this.warehouses = res.content.results;

          this.currentUser.countries.forEach(arg => {
              newArray.push(this.warehouses.find(x=> x.country === (arg.code||arg)))
          });

          this.warehouses=newArray
        },
        setDataSelect(model, data, splice) {
            for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
                if (
                this.configList.filters.elements.champs[i].type === "select" &&
                this.configList.filters.elements.champs[i].key === model
                ) {
                if (!splice) {
                    this.configList.filters.elements.champs[i].values = data;
                } else {
                    this.configList.filters.elements.champs.splice(i, 1);
                }
                }
            }
        },
        QuantityTotalOrPrices(type,data){
                var value=0;
                for (let i in data) {
                    if(type=='qte') value+=data[i].quantity;
                    else if(type=='fees') value+=data[i].price;
                    else value+=(parseFloat(data[i].price)*parseFloat(data[i].quantity));
                    
                }
                return value;
        },
        async getSellers() {
            if (this.currentUser.type !== "Seller") {
                const filter = {
                    type: "Seller",
                    countries:this.idWharhouse.country,
                    limit:10,
                };
                if (this.currentUser.type != "Seller") {
                    const res = await this.$server.search("users", filter);
                    if (res.content.results) this.sellers = res.content.results;
                    else this.sellers = [];
                }
            }
        },
        async reset(data) {
            let lmitt=this.limit;
            if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
            if(this.filters['limit']) lmitt=this.filters['limit'];
            this.filters['page']=1;
            this.$f.objectToQueryString({limit:lmitt})
            await this.getSourcings( {limit:lmitt});
        },
        async filtrer(data) {
         if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
          delete  this.filters.page;
          delete  this.filters.name;

         if(!this.filters['limit']) this.filters['limit']=this.limit;
         if(data.status) this.filters.status=data.status;else delete this.filters.status;
         if(data.country) this.filters.country=data.country.name;else delete this.filters.country;
         if(data.warehouse) this.filters['warehouse._id']=data.warehouse._id;else delete this.filters['warehouse._id'];
         if(data.seller) this.filters['seller._id']=data.seller._id;else delete this.filters['seller._id'];
         this.paginate.currentpage=1;
         this.$f.objectToQueryString(this.filters)
        await this.getSourcings(this.filters);
        },
        async search(data) {
             this.paginate= {total: 0,currentpage: 1,lastpage: 1,per_page: 0,};
            let limitt=this.limit
            if(this.filters['limit']) limitt=this.filters['limit'];
            const search = {
              name:data.trim(), limit:parseInt(limitt)
            }
            //search['warehouse.id']=this.idWharhouse._id
            this.$f.objectToQueryString(search)
            const res = await this.$server.find("sourcings", search);
              if (res.content.results) {
                this.sourcings = res.content.results;
                this.paginate.total = res.content.total;
                this.paginate.lastpage = res.content.last_page;
                this.paginate.per_page = res.content.per_page;
                this.paginate.currentpage = res.content.current_page;
                for (const element of  this.sourcings) {
                    // if(element.validationDate) element.validationDate=this.$moment(element.validationDate).format('yyyy-MM-DDTHH:mm');
                    // if(element.facturationDate) element.facturationDate=this.$moment(element.facturationDate).format('yyyy-MM-DDTHH:mm');
                    element.quantity=this.QuantityTotalOrPrices('qte',element.details);
                    element.totalPrice=this.QuantityTotalOrPrices('price',element.details)+this.QuantityTotalOrPrices('fees',element.fees);
                    element.Nb_views=element.messages.filter(x => x.access.type !== this.currentUser.type2 && !x.access.vue).length
                 }
            } else {
                this.sourcings = []; this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
            }
        },
        async getUser() {
            const res = await this.$me;
            this.currentUser = res.content;
        },
        async getSourcings(filters) {
            this.loading=true;
            //filters['warehouse._id'] = this.idWharhouse._id;
            if(!filters.limit) filters.limit=this.limit;
            const res = await this.$server.search("sourcings", filters);
            this.loading=false;
            this.ProcessingInfo.count=0;this.ProcessingInfo.total=0;
            if (res.content.results) {
                this.sourcings = res.content.results;
                this.paginate.total = res.content.total;
                this.paginate.lastpage = res.content.last_page;
                this.paginate.per_page = res.content.per_page;
                this.paginate.currentpage = res.content.current_page;
                 for (const element of  this.sourcings) {
                    element.quantity=this.QuantityTotalOrPrices('qte',element.details);
                    element.totalPrice=this.QuantityTotalOrPrices('price',element.details)+this.QuantityTotalOrPrices('fees',element.fees);
                    element.Nb_views=element.messages.filter(x => x.access.type !== this.currentUser.type2 && !x.access.vue).length
                    // if(element.isProcessed && element.status==="effectue") {
                    //     this.ProcessingInfo.total+=element.totalPrice;
                    //     this.ProcessingInfo.count++;
                    // }
                 }
            } else {
                this.sourcings = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
            }
        },
        async refresh() {
            if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
            this.$f.objectToQueryString(this.filters)
            if(this.filters.name) await this.search(this.filters.name);
            else await this.getSourcings(this.filters);
            if(this.filters['page'] && this.filters['page']>1 && this.sourcings.length==0){
                this.filters['page']= --this.filters['page'];
                this.$f.objectToQueryString(this.filters)
                await this.getSourcings(this.filters);
            }
        },
        showProducts(index) {
            if (this.element !== this.sourcings[index]) {
                this.element = this.sourcings[index];
                console.log("Je suis this.element", this.element);
            } else this.element = null;
        },
        async remove(id) {
            this.$confirm("You want to delete this sourcing?").then(async (res) => {
                if (res) {
                    const resp = await this.$server.delete("sourcings", { id: id });
                    await this.refresh();
                    alert("sourcing Deleted", "success");
                }
            });
        },
        async paginatation(direction){
            var page=0;
            if(direction == "next")  page=++this.paginate.currentpage;
            else page=--this.paginate.currentpage;

            if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
            this.filters['page']=page;
            this.$f.objectToQueryString(this.filters);

           await this.getSourcings(this.filters);
      },
    },
};
</script>