export default {
    list: {
        title: "Sourcings",
        name: 'sourcings',
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        sousTable: true,
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: true,
                name: "Actions",
                buttons: [{
                    text: "Add Sourcing",
                    icon: "add",
                    link: '/sourcings/new',
                    color: 'primary'
                }],
            }
        },
        columns: [
            {
                name: "ID",
                elements: [{
                    items: [{
                        name: "id",
                        class: "text-center",
                    }, ],
                }, ]
            },
            {
                name: "Seller",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "seller",
                        elements: [{
                            items: [{
                                    name: "fullName",
                                    class: "font-bold text-gray-700",
                                    
                                },
                            ]
                        }]
                    }],
                }, ]
            },
            {
                name: "Warehouse",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "warehouse",
                        elements: [{
                            items: [{
                                    name: "name",
                                    class: "font-bold text-gray-700",
                                    
                                },
                            ]
                        }]
                    }],
                }, ]
            },
            {
                name: "Pays d'achat",
                elements: [{
                    items: [{
                        name: "country",
                        class: "text-center font-bold",
                    }, ],
                }, ]
            },
           
            {
                name: "details",
                model: "sourcings",
                title: 'Products'
            },
            {
                name: "Quantity",
                elements: [{
                    items: [{
                        name: "quantity",
                        class: "text-center font-bold",
                    }, ],
                }, ]
            },
            {
                name: "Date Facturation",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "facturationDate",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Date Validation",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "validationDate",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Mode de paiement",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "paymentMethod",
                        elements: [{
                            items: [{
                                    name: "name",
                                    class: "font-bold text-gray-700",
                                    
                                },
                            ]
                        }]
                    }],
                }, ]
            },
            {
                name: "Amount",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "totalPrice",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "date_added",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "createdAt",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Status",
                class: "",
                elements: [{
                    items: [{
                        class: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
                        name: "status",
                        options: (item) => {
                            switch (item) {
                                case 'pending':
                                    return "color: rgb(233 147 19); border-color: rgb(233 147 19);"
                                    break;
                                case 'en attente de paiement':
                                    return "color:rgb(126 24 185); border-color: rgb(126 24 185);"
                                    break;
                                    
                                case 'valide':
                                    return "color:rgb(58 217 18); border-color: rgb(58 217 18);"
                                    break; 
                                case 'en attente de validation':
                                    return "color:rgb(18 23 217); border-color: rgb(18 23 217);"
                                    break;
                                case 'rejected':
                                    return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                                    break;
                            }
                        },
                    }],  
                }, ]
            },
        ],
        columnsDialog: {
            details: 'details',
            elements: [{
                    class: "",
                    name: "Image",
                    referent: "details",
                    attribut: 'productObject',
                    items: [{
                        name: "image",
                    }, ]
                },
                {
                    class: "",
                    name: "Image",
                    referent: "details",
                    attribut: 'productObject',
                    items: [{
                        name: "productId",
                    }, ]
                },
                {
                    class: "",
                    name: "Name",
                    referent: "details",
                    attribut: 'productObject',
                    items: [{
                        name: "productName",
                    }, ]
                },
                {
                    class: "",
                    name: "Quantity",
                    referent: "details",
                    items: [{
                        name: "quantity",
                    }, ]
                },
                {
                    class: "",
                    name: "Unit Price (MAD)",
                    referent: "details",
                    items: [{
                        name: "priceDH",
                    }, ]
                },
                {
                    class: "",
                    name: "Unit Price",
                    referent: "details",
                    items: [{
                        name: "price",
                    }, ]
                },
            ]
        },
        actions: {
            name: "Actions",
            width: "10px",
            class: "justify-end",
            elements: [
                {
                    svg:"M12 0c-6.627 0-12 4.975-12 11.111 0 3.497 1.745 6.616 4.472 8.652v4.237l4.086-2.242c1.09.301 2.246.464 3.442.464 6.627 0 12-4.974 12-11.111 0-6.136-5.373-11.111-12-11.111zm1.193 14.963l-3.056-3.259-5.963 3.259 6.559-6.963 3.13 3.259 5.889-3.259-6.559 6.963z",
                    color: "success",
                    role: true,
                    key: 'messages',
                    notifications: true,
                    class:"text-blue-500 fill-current",
                    tooltip: "messages",
                },
                {
                    icone: "remove_red_eye",
                    color: "success",
                    key: 'read',
                    id: "",
                    role: true,
                    link: '/sourcings/detail',
                    tooltip: "Update",
                },
                {
                    icone: "edit",
                    color: "success",
                    role: true,
                    key: 'update',
                    link: '/sourcings/edit',
                    tooltip: "Update",
                },
                {
                    icone: "delete",
                    color: "danger",
                    role: true,
                    key: 'delete',
                    tooltip: "Delete",
                }
            ],
        },
        filters: {
            name: 'sourcings',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "Id, Pays d'achat ....",
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [{
                        label: "Status",
                        key: "status",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Seller",
                        key: "seller",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Warehouses",
                        key: "warehouse",
                        name: "name",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Pays d'achat",
                        key: "country",
                        name: "name",
                        value: null,
                        values: [] ,
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                ],
            },
        },
    },
}